import React, { useState } from "react";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { TextField, Button, Checkbox, FormControlLabel } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

// Extend dayjs with plugins
dayjs.extend(utc);
dayjs.extend(timezone);

const ChatConfigurations = () => {
  const tz = "Africa/Johannesburg";

  const [selectedDays, setSelectedDays] = useState({
    Monday: false,
    Tuesday: false,
    Wednesday: false,
    Thursday: false,
    Friday: false,
    Saturday: false,
    Sunday: false,
  });

  const [startTime, setStartTime] = useState(dayjs().tz(tz).hour(8).minute(0));
  const [endTime, setEndTime] = useState(dayjs().tz(tz).hour(17).minute(0));
  const [isChanged, setIsChanged] = useState(false);

  const handleDayChange = (day) => {
    setSelectedDays((prevDays) => {
      const updatedDays = { ...prevDays, [day]: !prevDays[day] };
      setIsChanged(true);
      return updatedDays;
    });
  };

  const handleSave = () => {
    const configData = {
      days: selectedDays,
      startTime: startTime.tz(tz).format("HH:mm"), // Formats to only hours and minutes
      endTime: endTime.tz(tz).format("HH:mm"), // Formats to only hours and minutes
    };

    // Dispatch or save the config data
    console.log("Saving configuration:", configData);
    setIsChanged(false); // Reset change flag
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div>
        <h5>Manage Chat</h5>
        <div>
          <p>Available Days</p>
          {Object.keys(selectedDays).map((day) => (
            <FormControlLabel
              key={day}
              control={
                <Checkbox
                  checked={selectedDays[day]}
                  onChange={() => handleDayChange(day)}
                  name={day}
                />
              }
              label={day}
            />
          ))}
        </div>

        <div>
          <p>Support Hours</p>
          <div>
            <TimePicker
              label="Start Time"
              value={startTime}
              onChange={(newTime) => {
                setStartTime(newTime.tz(tz));
                setIsChanged(true);
              }}
              renderInput={(params) => <TextField {...params} />}
            />{" "}
            <TimePicker
              label="End Time"
              value={endTime}
              onChange={(newTime) => {
                setEndTime(newTime.tz(tz));
                setIsChanged(true);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </div>
        </div>
        <br />
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            disabled={!isChanged}
          >
            Save
          </Button>
        </div>
      </div>
    </LocalizationProvider>
  );
};

export default ChatConfigurations;
