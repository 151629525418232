import {
  createBrowserRouter,
  Route,
  redirect,
  createRoutesFromElements,
  Navigate,
} from "react-router-dom";
import HomePage from "../pages/Home";
import Login from "../pages/Login";
import Dashboard from "../pages/dashboard/dashboard";
import Chats from "../pages/chats/chats";
import Cases from "../pages/cases/cases";
import CasesList from "../components/cases/CasesList";
import CreateCase from "../components/cases/CreateCase";
import SingleCaseView from "../components/cases/SingleCaseView";
import Account from "../pages/account/Account";
import ControlPanel from "../pages/management/ControlPanel";
import Departments from "../components/control_panel/Departments";
import GeneralSettings from "../components/control_panel/GeneralSettings";
import Users from "../components/control_panel/Users";
import FAQs from "../components/control_panel/FAQs";
import ViewFAQs from "../pages/faqs/faqs";
import SingleFAQ from "../components/faqs/SingleFAQ";
import ListFAQs from "../components/faqs/ListFAQs";
import { useSelector } from "react-redux";
import Forbidden from "./Forbidden";
import UserProfileView from "../pages/account/UserProfileView";
import SLAs from "../components/control_panel/SLAs";
import Survey from "../pages/survey/Survey";
import Insights from "../pages/insights/Insights";
import SurveysView from "../components/insights/SurveysView";
import FeedbackView from "../components/insights/FeedbackView";
import SurveyQuestions from "../components/control_panel/SurveyQuestions";
import ClientSurvey from "../pages/survey/Survey";
import DepartmentsNew from "../components/control_panel/DepartmentsNew";

const loader = async () => {
  const isAuthinticated = localStorage.getItem("isAuthinticated");
  // console.log("Is the user Authinticated: 🔑🔑🔑🔑", isAuthinticated);
  if (!isAuthinticated) {
    return redirect("/");
  }
  return null;
};

const AdminElement = ({ children }) => {
  const authUser = useSelector((state) => state.auth.user);
  if (authUser.userInfo.isAdmin) return <>{children}</>;
  else return <Forbidden />;
};

const AgentElement = ({ children }) => {
  const authUser = useSelector((state) => state.auth.user);
  if (
    authUser.userInfo.isSuperAdmin ||
    authUser.userInfo.isAdmin ||
    authUser.userInfo.isSpecialist
  )
    return <>{children}</>;
  else return <Forbidden />;
};

const router = createBrowserRouter(
  createRoutesFromElements([
    <Route key="/login" path="/" element={<Login />} />,
    <Route key="survey" path="/survey" element={<Survey />} />,
    <Route
      path="/"
      element={<HomePage />}
      children={[
        <Route
          key="dashboard"
          index
          path="dashboard"
          element={<Dashboard />}
          loader={loader}
        />,
        <Route
          path="cases"
          element={<Cases />}
          children={[
            <Route
              key="cases_list"
              index
              path=""
              element={<CasesList />}
              loader={loader}
            />,
            <Route
              key="create_case"
              path="create_case"
              element={<CreateCase />}
              loader={loader}
            />,
            <Route
              key="single_case"
              path="single_case/:caseId"
              element={<SingleCaseView />}
              loader={loader}
            />,
          ]}
        />,

        <Route
          path="control_panel"
          element={
            <AdminElement>
              <ControlPanel />
            </AdminElement>
          }
          children={[
            // <Route
            //   key="departments"
            //   index
            //   path="departments"
            //   element={<Departments />}
            //   loader={loader}
            // />,
            <Route
              key="departments"
              index
              path="departments"
              element={<DepartmentsNew />}
              loader={loader}
            />,
            <Route
              key="create_case"
              path="create_case"
              element={<CreateCase />}
              loader={loader}
            />,
            <Route
              key="users"
              path="users"
              element={<Users />}
              loader={loader}
            />,
            <Route
              key="manage_faqs"
              path="manage_faqs"
              element={<FAQs />}
              loader={loader}
            />,
            <Route
              key="manage_slas"
              path="manage_slas"
              element={<SLAs />}
              loader={loader}
            />,
            <Route
              key="manage_survey_questions"
              path="manage_survey_questions"
              element={<SurveyQuestions />}
              loader={loader}
            />,
            <Route
              key="settings"
              path="settings"
              element={<GeneralSettings />}
              loader={loader}
            />,
          ]}
        />,

        <Route
          key="chats"
          path="chats"
          element={
            <AgentElement>
              <Chats />
            </AgentElement>
          }
          loader={loader}
        />,
        <Route
          path="faqs"
          element={<ViewFAQs />}
          children={[
            <Route
              key="faqs"
              index
              path=""
              element={<ListFAQs />}
              loader={loader}
            />,
            <Route key="view" path="view/:id" element={<SingleFAQ />} />,
          ]}
        />,
        <Route
          key="account"
          path="account"
          element={<Account />}
          loader={loader}
        />,
        <Route
          key="user_profile_view"
          path="user_profile_view/:id"
          element={<UserProfileView />}
          loader={loader}
        />,
        <Route
          key="insight"
          path="insights"
          element={<Insights />}
          loader={loader}
        >
          <Route key="surveys" index path="surveys" element={<SurveysView />} />
          ,
          <Route
            key="feedback"
            path="feedback"
            element={
              <AdminElement>
                <FeedbackView />
              </AdminElement>
            }
          />
          ,
        </Route>,
        <Route
          key="survey-client"
          path="client-survey"
          element={<ClientSurvey />}
        />,
      ]}
    />,
  ])
);

export default router;
