import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { subscribeToSurveys } from "../../slices/api/surveyApi";
import NameResolver from "../../components/common/NameResolver";

const SurveysView = () => {
  const dispatch = useDispatch();
  const authUser = useSelector((state) => state.auth);
  const surveys = useSelector((state) => state.surveys);

  useEffect(() => {
    let unsubscribe = false;

    if (authUser !== null && authUser !== undefined) {
      const isSuperAdmin = authUser.user.userInfo.isSuperAdmin ? true : false;

      unsubscribe = subscribeToSurveys(
        dispatch,
        authUser.user.userInfo.department,
        isSuperAdmin
      );
    }

    return () => {
      if (typeof unsubscribe === "function") {
        unsubscribe();
      }
    };
  }, [dispatch, authUser]);

  const filteredSurveys =
    surveys?.surveyAnswersList.filter(
      (survey) => survey.answers && Object.keys(survey.answers).length > 0
    ) || [];

  return (
    <div className="surveys-page">
      <h4>Surveys</h4>
      <div className="surveys-container">
        {filteredSurveys.length > 0 ? (
          filteredSurveys.map((survey, index) => (
            <div key={index} className="card-style shadow">
              <div className="card-harder">
                <h1><NameResolver email={survey.agentId} /> <span>Agent Name</span></h1>
                <p>
                  <span>Answered by: {survey.userId} </span> | {" "}
                  <span>Answered at: {new Date(survey.dateUpdated).toLocaleString()}</span>
                </p>
              </div>
              <hr className="h-divider" />
              {survey.answers && Object.keys(survey.answers).length > 0 ? (
                Object.entries(survey.answers).map(
                  ([question, answer], answerIndex) => (
                    <p key={answerIndex}>
                      {question}: 
                      <strong> {answer.charAt(0).toUpperCase() + answer.slice(1)}</strong>
                    </p>
                  )
                )
              ) : (
                <p>
                  No answers available.
                </p>
              )}
            </div>
          ))
        ) : (
          <p>No surveys available.</p>
        )}
      </div>
    </div>
  );
};

export default SurveysView;
