import React, { useEffect, useState } from "react";
import moment from "moment";
import InfoIcon from "@mui/icons-material/Info";
import { useDispatch } from "react-redux";
import ConfirmationDialog from "../../ConfirmationDialog";
import { updateCategoryData } from "../../../slices/api/departmentsApi";
import Checkbox from "@mui/material/Checkbox";
import {
  setShowToast,
  setToastMessage,
  setToastTimer,
} from "../../../slices/ToastSlice";
import { formatDepartmentName } from "../../../utils";

const DepartmentCard = ({ department }) => {
  const dispatch = useDispatch();
  const [feature, setFeature] = useState("");
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [callbackFunction, setCallbackFunction] = useState(null);
  const [isChatCheck, setChatChecked] = useState(department.chatEnabled);
  const [isCasesCheck, setCasesChecked] = useState(department.casesEnabled);

  useEffect(() => {
    if (department && Object.keys(department).length > 0) {
      setChatChecked(department.chatEnabled);
      setCasesChecked(department.casesEnabled);
    }
  }, [department]);

  //   const handleFeatureUpdate = (
  //     event,
  //     featureName,
  //     categoryID,
  //     currentStatus,
  //     updateFunction
  //   ) => {
  //     event.stopPropagation();
  //     setFeature(featureName);

  //     if (!currentStatus) {
  //       setCallbackFunction(
  //         () => () => updateFunction(categoryID, currentStatus)
  //       );
  //       setShowConfirmationDialog(true);
  //     } else {
  //       updateFunction(categoryID, currentStatus);
  //     }
  //   };

  const handleFeatureUpdate = (
    event,
    featureName,
    categoryID,
    currentStatus,
    updateFunction
  ) => {
    if (!currentStatus) {
      updateFunction(categoryID, currentStatus);
    } else {
      updateFunction(categoryID, currentStatus);
    }
  };

  const handleCategoryChatEnablement = async (id, value) => {
    try {
      const payload = {
        chatEnabled: value,
        dateUpdated: new Date().toString(),
      };
      await updateCategoryData(id, payload);
      dispatch(
        setToastMessage(
          `Successfully updated the department. Chat: ${
            value ? "enabled" : "disabled"
          }`
        )
      );
      dispatch(setToastTimer(3000));
      dispatch(setShowToast(true));
    } catch (error) {
      console.error(error);
      dispatch(
        setToastMessage(
          "An error occurred while trying to update the department."
        )
      );
      dispatch(setToastTimer(3000));
      dispatch(setShowToast(true));
    }
  };

  const handleCategoryCaseEnablement = async (id, value) => {
    try {
      const payload = {
        casesEnabled: value,
        dateUpdated: new Date().toString(),
      };
      await updateCategoryData(id, payload);
      dispatch(
        setToastMessage(
          `Successfully updated the department. Cases: ${
            value ? "enabled" : "disabled"
          }`
        )
      );
      dispatch(setToastTimer(3000));
      dispatch(setShowToast(true));
    } catch (error) {
      console.error(error);
      dispatch(
        setToastMessage(
          "An error occurred while trying to update the department."
        )
      );
      dispatch(setToastTimer(3000));
      dispatch(setShowToast(true));
    }
  };

  return (
    <>
      <div className="department-card shadow">
        <div className="card-header">
          <h2>
            {formatDepartmentName(department.name).length > 30
              ? formatDepartmentName(department.name).substring(0, 30) + "..."
              : formatDepartmentName(department.name)}
          </h2>
          <div className="card-details">
            <table>
              <tr>
                <td>Created</td>
                <th>
                  {moment(new Date(department.dateCreated))
                    // .add(2, "hours")
                    .format("DD-MM-YY - h:mm A")}
                </th>
              </tr>
              <tr>
                <td>Updated</td>
                <th>
                  {moment(new Date(department.dateUpdated))
                    .add(2, "hours")
                    .format("YYYY:MM:DD - h:mm A")}
                </th>
              </tr>
            </table>
          </div>
          {/* ABC{department.chatEnabled.toString()}
          ABC{department.casesEnabled.toString()} */}
          <div className="action-btns">
            <div className="check-item">
              <label htmlFor="chat_ckb">Live Chat</label>
              <Checkbox
                inputProps={{ "aria-label": "controlled" }}
                name="chat"
                id="chat_ckb"
                // defaultChecked={isChatCheck}
                defaultChecked
                checked={isChatCheck}
                onChange={(event) => {
                  event.stopPropagation();
                  const isChecked = event.target.checked;
                  setChatChecked(isChecked);
                  handleFeatureUpdate(
                    event,
                    "Chat",
                    department.id,
                    event.target.checked,
                    handleCategoryChatEnablement
                  );
                }}
              />
            </div>

            <div className="check-item">
              <label htmlFor="cases_ckb">Cases</label>
              <Checkbox
                inputProps={{ "aria-label": "controlled" }}
                name="cases"
                id="cases_ckb"
                // defaultChecked={department.casesEnabled}
                checked={isCasesCheck}
                onChange={(event) => {
                  event.stopPropagation();
                  const isChecked = event.target.checked;
                  setCasesChecked(isChecked);
                  handleFeatureUpdate(
                    event,
                    "Cases",
                    department.id,
                    event.target.checked,
                    handleCategoryCaseEnablement
                  );
                }}
              />
            </div>
          </div>
          <button type="button" className="info-btn">
            <InfoIcon />
          </button>
        </div>
      </div>
      {showConfirmationDialog && (
        <ConfirmationDialog
          message={`Are you sure you want to disable ${feature}?`}
          onCallback={callbackFunction}
          onCancel={() => setShowConfirmationDialog(false)}
          buttonText="Disable"
          buttonClassName="delete-btn"
        />
      )}
    </>
  );
};

export default DepartmentCard;
