import { useState } from "react";
import SubDepartments from "./departments/SubDepartments";
import MainDepartments from "./departments/MainDepartments";

const DepartmentsNew = () => {
  const [parentDepartment, setParentDepartment] = useState("main");
  const [showSubCategories, setShowSubCategories] = useState(false);

  const goBackToMainDepartments = (e) => {
    e.preventDefault();
    setParentDepartment("main");
    setShowSubCategories(false);
  };

  return (
    <div>
      {!showSubCategories ? (
        <MainDepartments
          setParentDepartment={setParentDepartment}
          setShowSubCategories={setShowSubCategories}
        />
      ) : (
        <SubDepartments
          goBackToMainDepartments={goBackToMainDepartments}
          parentDepartment={parentDepartment}
        />
      )}
    </div>
  );
};

export default DepartmentsNew;
