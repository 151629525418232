import React, { useState, useEffect } from 'react';
import { getAllSurveyQuestions, createSurveyQuestion, updateSurveyQuestion } from '../../slices/api/surveyApi';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const SurveyQuestions = () => {
    const [questions, setQuestions] = useState([]);
    const [newQuest, setNewQuest] = useState('');
    const [newQuestType, setNewQuestType] = useState('rating');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showAddQuest, setShowAddQuest] = useState(false);

    useEffect(() => {
        const fetchQuestions = async () => {
            const fetchedQuestions = await getAllSurveyQuestions();
            const sortedQuestions = fetchedQuestions.sort((a, b) => a.question_position - b.question_position);
            setQuestions(sortedQuestions);
        };

        fetchQuestions();
    }, []);

    const handleShowAddQuest = () => {
        setShowAddQuest(!showAddQuest);
    }

    const createSurveyQuest = async (event) => {
        event.preventDefault();
        setIsSubmitting(true);

        if(newQuest !== "") {
            const newQuestionData = {
                question: newQuest,
                question_type: newQuestType,
                date_created: { seconds: Math.floor(Date.now() / 1000), nanoseconds: 0 },
                question_position: questions.length + 1,
            };
            const newQuestion = await createSurveyQuestion(newQuestionData);
            setQuestions(prev => [...prev, newQuestion]);
        }

        setNewQuest('');
        setNewQuestType('');
        setShowAddQuest(false)
        setIsSubmitting(false);
    };

    const moveQuestion = async (index, direction) => {
        const updatedQuestions = [...questions];
        const currentQuestion = updatedQuestions[index];
        const swapIndex = direction === 'up' ? index - 1 : index + 1;

        if (swapIndex >= 0 && swapIndex < updatedQuestions.length) {
            const adjacentQuestion = updatedQuestions[swapIndex];
            const tempPosition = currentQuestion.question_position;
            currentQuestion.question_position = adjacentQuestion.question_position;
            adjacentQuestion.question_position = tempPosition;
            [updatedQuestions[index], updatedQuestions[swapIndex]] = [updatedQuestions[swapIndex], updatedQuestions[index]];
            setQuestions(updatedQuestions);

            await updateOrderInFirestore([currentQuestion, adjacentQuestion]);
        }
    };

    const updateOrderInFirestore = async (updatedQuestions) => {
        try {
            for (let question of updatedQuestions) {
                await updateSurveyQuestion(question.id, {
                    question_position: question.question_position,
                });
            }
            console.log('Order updated successfully!');
        } catch (error) {
            console.error('Error updating order:', error);
        }
    };

    return (
        <div id='survey_questions'>
            <div className="header-part">
                <h2>Survey Questions</h2>
                <button type="button" className="main-btn" onClick={handleShowAddQuest}>
                    Add New Question
                </button>
                { showAddQuest && (
                    <div className='new-question shadow'>
                        <div className='card-header'>
                            <h4>Add New Question</h4>
                        </div>
                        <div className='add-content'>
                            <div className="input-container">
                                <label htmlFor="question" className="required-field">Question</label>
                                <input type="text" name='question' id='question' className='question-field' placeholder='Enter Question' value={newQuest} onChange={(e) => setNewQuest(e.target.value)} required/>
                            </div>
                            <div className="input-container" >
                                <label htmlFor="" className="required-field">Question Type</label>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="question_type" id="question_type1" value="rating" onChange={(e) => setNewQuestType(e.target.value)} checked required />
                                    <label className="form-check-label" for="question_type1">
                                        Rating Question
                                    </label>
                                    </div>
                                    <div class="form-check">
                                    <input className="form-check-input" type="radio" name="question_type" id="question_type2" value="text" onChange={(e) => setNewQuestType(e.target.value)} required />
                                    <label className="form-check-label" for="question_type2">
                                        Text Question
                                    </label>
                                </div>
                            </div>
                            <div className="btn-container">
                                <button
                                type="button"
                                className="main-btn"
                                onClick={createSurveyQuest}
                                disabled={isSubmitting}
                                >
                                    {isSubmitting ? "Adding..." : "Add Question"}
                                
                                </button>
                                <button
                                type="button"
                                className="no-border-btn"
                                onClick={() =>
                                    setShowAddQuest(!showAddQuest)
                                }
                                >
                                Cancel
                                </button>
                            </div>
                        </div>

                    </div>
                )}
            </div>

            <div className="quest-container">
                {questions.map((question, index) => (
                    <div key={question.id} className="question-item shadow-sm">
                        <div className="text-side">
                            <h4>{question.question}</h4>
                            <span>{question.question_type}</span>
                        </div>
                        <div className="order-buttons">
                            <button
                                onClick={() => moveQuestion(index, 'up')}
                                disabled={index === 0}
                            >
                                <ArrowUpwardIcon />
                            </button>
                            <button
                                onClick={() => moveQuestion(index, 'down')}
                                disabled={index === questions.length - 1}
                            >
                                <ArrowDownwardIcon />
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default SurveyQuestions