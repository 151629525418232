import React from "react";
import { useSelector } from "react-redux";

const DashboardTable = () => {
    const cases = useSelector((state) => state.cases.casesList);

    const openCases = cases.filter((item) => item.status !== "closed");

    const openCasesByEmail = openCases.filter((item) => item.channel === "EMAIL_SERVICE").length;
    const openCasesByWebChat = openCases.filter((item) => item.channel === "WEB_CHAT").length;
    const openCasesByWhatsApp = openCases.filter((item) => item.channel === "WHATSAPP").length;
    const openCasesByServiceDeskPortals = openCases.filter((item) => item.channel !== "EMAIL_SERVICE" || "WHATSAPP" || "WEB_CHAT").length;

    const casesOnHoldEmail = openCases.filter((item) => (item.channel === "EMAIL_SERVICE") && (item.status === 'on hold')).length;
    const casesOnHoldWebChat = openCases.filter((item) => (item.channel === "WEB_CHAT") && (item.status === 'on hold')).length;
    const casesOnHoldWhatsApp = openCases.filter((item) => (item.channel === "WHATSAPP") && (item.status === 'on hold')).length;
    const casesOnHoldServiceDeskPortals = openCases.filter((item) => (item.channel !== "EMAIL_SERVICE" || "WHATSAPP" || "WEB_CHAT") && (item.status === 'on hold')).length;

    const casesOverdueEmail = openCases.filter((item) => (item.channel === "EMAIL_SERVICE") && (item.slaEscalated === true)).length;
    const casesOverdueWebChat = openCases.filter((item) => (item.channel === "WEB_CHAT") && (item.slaEscalated === true)).length;
    const casesOverdueWhatsApp = openCases.filter((item) => (item.channel === "WHATSAPP") && (item.slaEscalated === true)).length;
    const casesOverdueServiceDeskPortal = openCases.filter((item) => (item.channel === item.channel !== "EMAIL_SERVICE" || "WHATSAPP" || "WEB_CHAT") && (item.slaEscalated === true)).length;

    const totalCasesOnHold = casesOnHoldEmail + casesOnHoldServiceDeskPortals + casesOnHoldWebChat + casesOnHoldWhatsApp;

    const totalCasesOverdue = casesOverdueEmail + casesOverdueServiceDeskPortal + casesOverdueWebChat + casesOverdueWhatsApp;

    return (
        <div className='dashboard-tbl'>
            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th>OPEN</th>
                        <th>ON HOLD</th>
                        <th>OVERDUE</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Service Desk Portals</td>
                        <td>{openCasesByServiceDeskPortals - openCasesByEmail - openCasesByWhatsApp - openCasesByWebChat}</td>
                        <td>{casesOnHoldServiceDeskPortals}</td>
                        <td>{casesOverdueServiceDeskPortal}</td>
                    </tr>
                    <tr>
                        <td>Email</td>
                        <td>{openCasesByEmail}</td>
                        <td>{casesOnHoldEmail}</td>
                        <td>{casesOverdueEmail}</td>
                    </tr>
                    <tr>
                        <td>WhatsApp</td>
                        <td>{openCasesByWhatsApp}</td>
                        <td>{casesOnHoldWhatsApp}</td>
                        <td>{casesOverdueWhatsApp}</td>
                    </tr>
                    <tr>
                        <td>Web Chat</td>
                        <td>{openCasesByWebChat}</td>
                        <td>{casesOnHoldWebChat}</td>
                        <td>{casesOverdueWebChat}</td>
                    </tr>

                    <tr className='totals'>
                        <th>Total</th>
                        <th>{openCases.length}</th>
                        <th>{totalCasesOnHold}</th>
                        <th>{totalCasesOverdue}</th>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default DashboardTable;