import ChatConfigurations from "./general/ChatConfigurations ";

const GeneralSettings = () => {
  return (
    <>
      <h5>
        <b>General Settings</b>
      </h5>
      <br></br>
      <ChatConfigurations />
    </>
  );
};

export default GeneralSettings;
