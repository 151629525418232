import { useEffect, useState } from "react";
import Search from "@mui/icons-material/Search";
import Clear from "@mui/icons-material/Clear";
import { TextField, InputAdornment, Pagination } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { fetchDepartments } from "../../../slices/api/departmentsApi";
import DepartmentCard from "./DepartmentCard";

const MainDepartments = ({ setParentDepartment, setShowSubCategories }) => {
  const ITEMS_PER_PAGE = 12;
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const { departmentsList } = useSelector((state) => state.departments);
  const { isAdmin, department } = useSelector((state) => ({
    isAdmin: state.auth.user.userInfo.isAdmin,
    department: state.auth.user.userInfo.department,
  }));

  useEffect(() => {
    if (isAdmin !== undefined && department) {
      fetchDepartments(dispatch, isAdmin, department);
    }
  }, [dispatch, isAdmin, department]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
    setCurrentPage(1);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const filteredDepartments = departmentsList.filter(
    (category) =>
      category.parentCategory === "main" &&
      category.name.toLowerCase().includes(searchTerm)
  );

  const totalPages = Math.ceil(filteredDepartments.length / ITEMS_PER_PAGE);

  const paginatedCategories = filteredDepartments.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const handleDepartmentClick = (event, department) => {
    event.stopPropagation();
    const idOfClickedItem = event.target.id;

    if (["cases_ckb", "chat_ckb"].indexOf(idOfClickedItem) == -1) {
      setParentDepartment(department);
      setShowSubCategories(true);
    }
  };

  return (
    <>
      <TextField
        label="Search"
        variant="outlined"
        value={searchTerm}
        onChange={handleSearch}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {searchTerm && (
                <Clear
                  onClick={() => setSearchTerm("")}
                  style={{ cursor: "pointer" }}
                />
              )}
              <Search />
            </InputAdornment>
          ),
        }}
      />
      <div className="departments-list-main-container">
        <div className="departments-list-container">
          <div className="departments-list p-3">
            {paginatedCategories.map((department, index) => (
              <div
                key={index}
                onClick={(event) => handleDepartmentClick(event, department)}
                id="department"
              >
                <DepartmentCard department={department} />
              </div>
            ))}
          </div>
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
            sx={{ position: "absolute", bottom: 0, width: "100%" }} // Fixed position
          />
        </div>
      </div>
    </>
  );
};

export default MainDepartments;
